import { Button as ButtonBase } from '@components/common/Button';
import { MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import styled from 'styled-components';

export const Container = styled.div<{ tabletWidth?: boolean; alignLeft?: boolean }>`
  border: ${({ theme }) => `2px solid ${theme.colors.title}`};
  border-left: ${({ tabletWidth }) => !tabletWidth && 'none'};
  border-right: ${({ tabletWidth }) => !tabletWidth && 'none'};
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'space-around')};
  flex-direction: column;
  padding: 1rem 1rem 2rem 1rem;
  background-image: url('img/not-found-mask.webp');
  background-repeat: no-repeat;
  background-position: bottom;
  height: auto;
  padding-top: ${({ tabletWidth }) => !tabletWidth && '1.5rem'};

  @media ${MEDIA_QUERIES.tablet} {
    flex-direction: row;
    padding: ${({ alignLeft }) => (alignLeft ? '0 0 0 2rem' : '0')};
    height: 31.25rem;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  @media ${MEDIA_QUERIES.tablet} {
    max-width: 50%;
  }
`;

export const Content = styled.div`
  max-width: 34.5rem;
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme.colors.title};
  line-height: 2.7rem;
  font-size: 2.25rem;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 0.5rem;

  @media (min-width: 1024px) {
    line-height: 4.55rem;
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.title};
  line-height: 1.4rem;
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 2rem;

  @media ${MEDIA_QUERIES.tablet} {
    line-height: 1.6rem;
    font-size: ${({ theme }) => theme.fontSize.large};
  }
`;

export const Button = styled(ButtonBase)`
  background-color: transparent;
  width: 100%;

  @media ${MEDIA_QUERIES.tablet} {
    line-height: 1.6rem;
    font-size: ${({ theme }) => theme.fontSize.large};
    width: auto;
  }
`;

export const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.title};
  line-height: 1.8rem;
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 0 1rem;

  @media ${MEDIA_QUERIES.tablet} {
    line-height: 3rem;
    font-size: 2rem;
    margin-top: 5.5rem;
  }

  @media (min-width: 1024px) {
    padding: 0;
  }
`;
