
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ErrorPage } from '@components/errors/ErrorPage/ErrorPage';
import { fetchDeletedLinks } from '@queries/useDeletedLinks';
import * as Sentry from '@sentry/nextjs';
import { getTypeBySlug } from '@utils/getTypeBySlug';
import NextErrorComponent from 'next/error';

const MyError = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
    // Flushing is not required in this case as it only happens on the client
  }

  if (statusCode === 404 || statusCode === 410) {
    return (
      <ErrorPage
        translation={statusCode === 404 ? 'not-found' : 'gone'}
        image={statusCode === 404}
      />
    );
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

MyError.getInitialProps = async (ctx) => {
  const { res, err, asPath, query } = ctx;
  await Sentry.captureUnderscoreErrorException(ctx);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  });

  const type = getTypeBySlug(asPath);

  if (type) {
    try {
      const slug = String(query.slugs ?? query.slug);
      const deletedArticle = await fetchDeletedLinks(type, slug);

      const isRedirectedSlug = deletedArticle.redirectTo && deletedArticle.redirectTo !== slug;

      if (deletedArticle.status === 'not-found' && !isRedirectedSlug) {
        res.statusCode = 410;
        errorInitialProps.statusCode = 410;
      }
    } catch (e) {
      // do nothing, status code 404 will be returned
    }
  }

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  errorInitialProps.hasGetInitialPropsRun = true;
  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return {
      ...errorInitialProps,
      noPadding: true,
      noBackground: true,
      globalSettings: {},
    };
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`));
  await Sentry.flush(2000);

  return {
    ...errorInitialProps,
    noPadding: true,
    noBackground: true,
    globalSettings: {},
  };
};

const __Page_Next_Translate__ = MyError;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  