import { Article } from '@api/api';
import { ArticleCard } from '@components/common/Card/ArticleCard';
import { DataFeed } from '@components/common/DataFeed';
import { buildArticleLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import NotFoundImage from './404.webp';
import { Button, Container, Content, H2, Header, Image, Text } from './Error.styles';
import { ArticlesProps, Banner as BannerType } from './Error.types';

export const Banner = ({ tabletWidth, image, translation }: BannerType): ReactElement => {
  const router = useRouter();
  const { t } = useTranslation(translation);

  return (
    <Container tabletWidth={tabletWidth} alignLeft={!image}>
      {image && <Image src={NotFoundImage.src} alt="404" />}
      <Content>
        <Header>{t('title')}</Header>
        <Text>{t('text')}</Text>
        <Button text={t('button')} onClick={() => router.push('/')} />
      </Content>
    </Container>
  );
};

export const Articles = ({
  articles,
  navigationItems,
  onButtonClick,
  disableButton,
  activeTab,
  onActiveTabChange,
}: ArticlesProps): ReactElement => {
  const { t } = useTranslation('not-found');
  const [showArticles, setShowArticles] = useState(false);

  useEffect(() => {
    if (articles?.length > 0) setShowArticles(true);
  }, [articles]);

  return (
    showArticles && (
      <>
        <H2>{t('interested-in')}</H2>
        <DataFeed<Article>
          data={articles}
          loading={false}
          onLoadMoreClick={onButtonClick}
          loadMoreDisabled={disableButton}
          loadMoreText={t('load-more')}
          renderData={(article) => (
            <ArticleCard
              article={article}
              href={buildArticleLink(article?.id, article?.slug)}
              variant="large"
              thumbnail="left"
              size="large"
            />
          )}
          navigationItems={navigationItems}
          activeNavigationItem={activeTab}
          variant="list"
          hideHeader={Boolean(true)}
          onActiveTabChange={onActiveTabChange}
        />
      </>
    )
  );
};
