import { DeletedLinksTypeEnum } from '@api/api';

export const getTypeBySlug = (slug: string): DeletedLinksTypeEnum | null => {
  const isImage = slug.includes('fill') || slug.includes('webp');

  const splittedSlug = slug.split('/');
  splittedSlug.shift();

  const type = splittedSlug[0];

  if (isImage) {
    return null;
  }

  if (type === 'authors') {
    return DeletedLinksTypeEnum.Writer;
  }

  if (type === 'tags') {
    return DeletedLinksTypeEnum.Tag;
  }

  if (Number(type)) {
    return DeletedLinksTypeEnum.Article;
  }

  if (splittedSlug.length === 1) {
    return DeletedLinksTypeEnum.Category;
  }

  if (splittedSlug.length === 2) {
    return DeletedLinksTypeEnum.Subcategory;
  }

  return null;
};
