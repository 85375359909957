import { Articles, Banner } from '@components/errors/ErrorPage';
import { useLatestStories } from '@queries/useLatestStories';
import { useTodayArticles } from '@queries/useTodayArticles';
import { MEDIA_QUERIES, useMediaQuery } from '@student-coin/coinpaper-components';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { ErrorPageProps } from './Error.types';

export const ErrorPage = ({ image, translation }: ErrorPageProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState('All');
  const [tabletWidth, setTabletWidth] = useState(false);
  const isTabletWide = useMediaQuery(MEDIA_QUERIES.tablet);
  const { t } = useTranslation(translation);

  useEffect(() => {
    setTabletWidth(isTabletWide);
  }, [isTabletWide]);

  const fetches = { All: useLatestStories(), Today: useTodayArticles() };

  const navigationItems = [
    {
      name: t('all'),
      tag: 'span',
    },
    { name: t('today'), tag: 'span' },
  ];
  const onLoadMoreArticles = () => fetches[activeTab].fetchNextPage();

  return (
    <>
      <Banner tabletWidth={tabletWidth} image={image} translation={translation} />
      <Articles
        articles={fetches[activeTab].data?.pages?.map(({ items }) => items).flat() || []}
        navigationItems={navigationItems}
        activeTab={activeTab}
        onButtonClick={onLoadMoreArticles}
        disableButton={!fetches[activeTab].hasNextPage || fetches[activeTab].isFetchingNextPage}
        onActiveTabChange={(tab) => setActiveTab(tab)}
      />
    </>
  );
};
