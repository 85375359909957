import { DeletedLinks, DeletedLinksTypeEnum } from '@api/api';
import { deletedLinksApiClient } from '@api/clients';
import { useQuery } from 'react-query';

export const fetchDeletedLinks = async (
  type: DeletedLinksTypeEnum,
  slug: string,
): Promise<DeletedLinks> => {
  const { data } = await deletedLinksApiClient.deletedLinksGet({
    type,
    slug,
  });

  return data;
};

export const useDeletedLinks = (type: DeletedLinksTypeEnum, slug: string) => {
  return useQuery(['deletedLinks', type, slug], () => fetchDeletedLinks(type, slug));
};
